import { FC, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import Holding from '@/screens/layouts/Holding';
import MainLayout from '@/screens/layouts/MainLayout';

const Main: FC = () => {
    return (
        <MainLayout>
            <Suspense fallback={<Holding {...{ spinner: true }} />}>
                <Outlet />
            </Suspense>
        </MainLayout>
    );
};

export default Main;
