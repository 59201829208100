import apiSlice from '../apiSlice';

const userBillingApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getUserInvoices: builder.mutation<Invoices, null>({
            query: () => ({
                url: 'user/billing/invoices',
                method: 'GET',
            }),
        }),
        getUserBilling: builder.mutation<UserBillingPortalResponse, null>({
            query: () => ({
                url: 'user/billing/portal',
                method: 'GET',
            }),
        }),
        userCheckout: builder.mutation<UserCheckoutResponse, UserCheckoutRequest>({
            query: ({ price }) => ({
                url: 'user/billing/checkout',
                method: 'POST',
                body: { price },
            }),
        }),
        getUserInvoice: builder.query<Invoice, GetUserInvoiceRequest>({
            query: ({ invoiceId }) => ({
                url: `user/billing/invoices/${invoiceId}`,
                method: 'GET',
            }),
        }),
    }),
});

export default userBillingApi;
export const {
    useGetUserInvoicesMutation,
    useGetUserBillingMutation,
    useUserCheckoutMutation,
    useGetUserInvoiceQuery,
} = userBillingApi;
