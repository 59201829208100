import apiSlice from '../apiSlice';

const assetApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getRecentAssets: builder.query<GetAssetsResponse, GetAssetsRequest>({
            query: ({ projectId }) => ({
                url: `developer/projects/${projectId}/assets?include=collections,user&sort=-updated&limit=15`,
                method: 'GET',
            }),
        }),
        getRecentTrashedAssets: builder.query<GetAssetsResponse, GetAssetsRequest>({
            query: ({ projectId }) => ({
                url: `developer/projects/${projectId}/assets?filter[trashed]=only&include=user&sort=-updated&limit=15`,
                method: 'GET',
            }),
            providesTags: ['recent-trashed-models'],
        }),
        getAllAvailableAssets: builder.query<Asset[], GetAssetsRequest>({
            query: ({ projectId }) => ({
                url: `developer/projects/${projectId}/assets?filter[pending]=false&limit=-1`,
                method: 'GET',
            }),
        }),
        getAssets: builder.mutation<GetAssetsResponse, GetAssetsRequest>({
            query: ({ options, projectId }) => ({
                url: `developer/projects/${projectId}/assets${options}`,
                method: 'GET',
            }),
        }),
        getAssetsWithCollection: builder.query<Asset[], GetAssetsForCollectionRequest>({
            query: ({ projectId, collectionId }) => ({
                url: `developer/projects/${projectId}/assets?filter[hasCollection]=${collectionId}&limit=-1`,
                method: 'GET',
            }),
        }),
        getAssetsWithoutCollection: builder.query<Asset[], GetAssetsForCollectionRequest>({
            query: ({ projectId, collectionId }) => ({
                url: `developer/projects/${projectId}/assets?filter[withoutCollection]=${collectionId}&limit=-1`,
                method: 'GET',
            }),
        }),
        getAsset: builder.mutation<Asset, GetAssetRequest>({
            query: ({ projectId, assetId, options }) => ({
                url: `developer/projects/${projectId}/assets/${assetId}${options}`,
                method: 'GET',
            }),
        }),
        storeAsset: builder.mutation<Asset, StoreAssetRequest>({
            query: ({ projectId, collection_id }) => ({
                url: `developer/projects/${projectId}/assets`,
                method: 'POST',
                body: { collection_id },
            }),
        }),
        storeAssetIngest: builder.mutation<Asset, StoreAssetIngestRequest>({
            query: ({ projectId, assetId, body }) => ({
                url: `developer/projects/${projectId}/assets/${assetId}/ingest`,
                method: 'POST',
                body: body,
            }),
        }),
        destroyAssetIngest: builder.mutation<Asset, DestroyAssetIngestRequest>({
            query: ({ projectId, assetId, filename }) => ({
                url: `developer/projects/${projectId}/assets/${assetId}/ingest/destroy`,
                method: 'PUT',
                body: { filename },
            }),
        }),
        analyzeAssetIngest: builder.mutation<Asset, AnalyzeAssetIngestRequest>({
            query: ({ projectId, assetId }) => ({
                url: `developer/projects/${projectId}/assets/${assetId}/ingest/analyze`,
                method: 'PUT',
            }),
        }),
        configureAssetIngest: builder.mutation<Asset, ConfigureAssetIngestRequest>({
            query: ({ projectId, assetId, source, extract_glb_textures }) => ({
                url: `developer/projects/${projectId}/assets/${assetId}/ingest/options`,
                method: 'PUT',
                body: { source, extract_glb_textures },
            }),
        }),
        finalizeAssetIngest: builder.mutation<Asset, FinalizeAssetIngestRequest>({
            query: ({ projectId, assetId }) => ({
                url: `developer/projects/${projectId}/assets/${assetId}/ingest/finalize`,
                method: 'PUT',
            }),
        }),
        processAssetUvIslands: builder.mutation<Asset, AssetUvIslandsRequest>({
            query: ({ projectId, assetId }) => ({
                url: `developer/projects/${projectId}/assets/${assetId}/pipeline/uv-islands`,
                method: 'PUT',
            }),
        }),
        processAssetUvUnwrap: builder.mutation<Asset, AssetUvUnwrapRequest>({
            query: ({ projectId, assetId, type }) => ({
                url: `developer/projects/${projectId}/assets/${assetId}/pipeline/uv-unwrap`,
                method: 'PUT',
                body: { type },
            }),
        }),
        processAssetSetOrigin: builder.mutation<Asset, AssetSetOriginRequest>({
            query: ({ projectId, assetId, type, center }) => ({
                url: `developer/projects/${projectId}/assets/${assetId}/pipeline/set-origin`,
                method: 'PUT',
                body: { type, center },
            }),
        }),
        updateAssetMaterialsMap: builder.mutation<Asset, UpdateAssetMaterialsMapRequest>({
            query: ({
                projectId,
                assetId,
                material,
                use_backface_culling,
                slot,
                enabled,
                filename,
                value,
                channel,
                flip_x_channel,
                flip_y_channel,
                subsurface,
                alpha,
                emission,
                occlusion,
            }) => ({
                url: `developer/projects/${projectId}/assets/${assetId}/materials/map`,
                method: 'PUT',
                body: {
                    material,
                    use_backface_culling,
                    slot,
                    enabled,
                    filename,
                    value,
                    channel,
                    flip_x_channel,
                    flip_y_channel,
                    subsurface,
                    alpha,
                    emission,
                    occlusion,
                },
            }),
        }),
        storeAssetMaterial: builder.mutation<Asset, StoreAssetMaterialRequest>({
            query: ({ projectId, assetId, form }) => ({
                url: `developer/projects/${projectId}/assets/${assetId}/materials/textures`,
                method: 'POST',
                body: form,
            }),
        }),
        destroyAssetMaterial: builder.mutation<Asset, DestroyAssetMaterialRequest>({
            query: ({ projectId, assetId, filename }) => ({
                url: `developer/projects/${projectId}/assets/${assetId}/materials/textures/remove`,
                method: 'PUT',
                body: { filename },
            }),
        }),
        storeAssetAttachment: builder.mutation<Asset, StoreAssetAttachmentRequest>({
            query: ({ projectId, assetId, form }) => ({
                url: `developer/projects/${projectId}/assets/${assetId}/attachments`,
                method: 'POST',
                body: form,
            }),
        }),
        destroyAssetAttachment: builder.mutation<Asset, DestroyAssetAttachmentRequest>({
            query: ({ projectId, assetId, attachment }) => ({
                url: `developer/projects/${projectId}/assets/${assetId}/attachments/remove`,
                method: 'PUT',
                body: { attachment },
            }),
        }),
        updateAsset: builder.mutation<Asset, UpdateAssetRequest>({
            query: ({
                projectId,
                assetId,
                name,
                is_shared,
                shared_password,
                is_downloadable_shared,
                is_versions_shared,
                is_materials_shared,
                remake_shareable_code,
                is_approved,
                is_published,
                tags,
                collections,
            }) => ({
                url: `developer/projects/${projectId}/assets/${assetId}`,
                method: 'PUT',
                body: {
                    name,
                    is_shared,
                    shared_password,
                    is_downloadable_shared,
                    is_versions_shared,
                    is_materials_shared,
                    remake_shareable_code,
                    is_approved,
                    is_published,
                    tags,
                    collections,
                },
            }),
        }),
        shareAssetLink: builder.mutation<null, ShareAssetLinkRequest>({
            query: ({ projectId, assetId, email }) => ({
                url: `developer/projects/${projectId}/assets/${assetId}/shareLink`,
                method: 'POST',
                body: { email },
            }),
        }),
        storeAssetThumbnail: builder.mutation<Asset, StoreAssetThumbnailRequest>({
            query: ({ projectId, assetId, form }) => ({
                url: `developer/projects/${projectId}/assets/${assetId}/thumbnail`,
                method: 'POST',
                body: form,
            }),
        }),
        copyAsset: builder.mutation<Copy, CopyAssetRequest>({
            query: ({ projectId, assetId, target_self, project_id, collection_id }) => ({
                url: `developer/projects/${projectId}/assets/${assetId}/copy`,
                method: 'POST',
                body: { target_self, project_id, collection_id },
            }),
        }),
        copyAssets: builder.mutation<{ batch_id: string }, CopyAssetsRequest>({
            query: ({ projectId, assets, target_self, project_id, collection_id }) => ({
                url: `developer/projects/${projectId}/assets/bulk/copy`,
                method: 'POST',
                body: { assets, target_self, project_id, collection_id },
            }),
        }),
        moveAsset: builder.mutation<AssetMove, MoveAssetRequest>({
            query: ({ projectId, assetId, project_id }) => ({
                url: `developer/projects/${projectId}/assets/${assetId}/move`,
                method: 'POST',
                body: { project_id },
            }),
        }),
        moveAssets: builder.mutation<{ batch_id: string }, MoveAssetsRequest>({
            query: ({ projectId, project_id, assets }) => ({
                url: `developer/projects/${projectId}/assets/bulk/move`,
                method: 'POST',
                body: { project_id, assets },
            }),
        }),
        downloadAsset: builder.mutation<Download, DownloadAssetRequest>({
            query: ({ projectId, assetId, options }) => ({
                url: `developer/projects/${projectId}/assets/${assetId}/download`,
                method: 'POST',
                body: options,
            }),
        }),
        archiveAsset: builder.mutation<null, DeleteAssetRequest>({
            query: ({ projectId, assetId }) => ({
                url: `developer/projects/${projectId}/assets/${assetId}`,
                method: 'DELETE',
            }),
        }),
        archiveAssets: builder.mutation<null, ArchiveAssetsRequest>({
            query: ({ projectId, assets }) => ({
                url: `developer/projects/${projectId}/assets/bulk/archive`,
                method: 'PUT',
                body: { assets },
            }),
        }),
        restoreAsset: builder.mutation<Asset, RestoreAssetRequest>({
            query: ({ projectId, assetId }) => ({
                url: `developer/projects/${projectId}/assets/${assetId}/restore`,
                method: 'PUT',
            }),
        }),
        restoreAssets: builder.mutation<null, RestoreAssetsRequest>({
            query: ({ projectId, assets }) => ({
                url: `developer/projects/${projectId}/assets/bulk/restore`,
                method: 'PUT',
                body: { assets },
            }),
        }),
        purgeAsset: builder.mutation<null, DeleteAssetRequest>({
            query: ({ projectId, assetId }) => ({
                url: `developer/projects/${projectId}/assets/${assetId}/purge`,
                method: 'DELETE',
            }),
        }),
        emptyAssetsTrash: builder.mutation<null, EmptyAssetsTrashRequest>({
            query: ({ projectId }) => ({
                url: `developer/projects/${projectId}/assets-trash`,
                method: 'DELETE',
            }),
            invalidatesTags: ['recent-trashed-models'],
        }),
        updateAssetNodes: builder.mutation<Asset, UpdateAssetNodesRequest>({
            query: ({ projectId, assetId, nodes }) => ({
                url: `developer/projects/${projectId}/assets/${assetId}/nodes`,
                method: 'PUT',
                body: { nodes },
            }),
        }),
        updateAssetHiddenNodes: builder.mutation<Asset, UpdateAssetHiddenNodesRequest>({
            query: ({ projectId, assetId, hidden_nodes }) => ({
                url: `developer/projects/${projectId}/assets/${assetId}/hiddenNodes`,
                method: 'PUT',
                body: { hidden_nodes },
            }),
        }),
        updateDefaultAssetScene: builder.mutation<null, UpdateAssetDefaultSceneRequest>({
            query: ({
                projectId,
                assetId,
                presentation,
                lighting,
                arcCamera,
                universalCamera,
                defaultCamera,
                setGeometryToOrigin,
                isMediaTransparent,
                mediaDimensions,
            }) => ({
                url: `developer/projects/${projectId}/assets/${assetId}/scene`,
                method: 'PUT',
                body: {
                    presentation,
                    lighting,
                    arcCamera,
                    universalCamera,
                    defaultCamera,
                    setGeometryToOrigin,
                    isMediaTransparent,
                    mediaDimensions,
                },
            }),
        }),
        deleteDefaultAssetScene: builder.mutation<null, DeleteAssetDefaultSceneRequest>({
            query: ({ projectId, assetId }) => ({
                url: `developer/projects/${projectId}/assets/${assetId}/scene`,
                method: 'DELETE',
            }),
        }),
        getSharedAsset: builder.mutation<Asset, GetSharedAssetRequest>({
            query: ({ code, options }) => ({
                url: `shared/assets/${code}${options}`,
                method: 'GET',
            }),
        }),
        sharedAssetGrant: builder.mutation<SharedResourceGrant, SharedAssetGrantRequest>({
            query: ({ code, password, name }) => ({
                url: `shared/assets/${code}/grant`,
                method: 'POST',
                body: { password, name },
            }),
        }),
        getSharedAssetMembers: builder.mutation<Asset, GetSharedAssetRequest>({
            query: ({ code, options }) => ({
                url: `shared/assets/${code}${options}`,
                method: 'GET',
            }),
        }),
        getSharedAssetMembersForMentions: builder.query<MentionDict[], GetSharedAssetMembersRequest>({
            query: ({ code }) => ({
                url: `shared/assets/${code}/membersToMention`,
                method: 'GET',
            }),
            providesTags: ['project-members-to-mention'],
            transformResponse: (response: User[]) =>
                response.map((user): MentionDict => ({ id: user.id, display: user.name })),
        }),
        downloadSharedAsset: builder.mutation<Download, DownloadSharedAssetRequest>({
            query: ({ code, options }) => ({
                url: `shared/assets/${code}/download`,
                method: 'POST',
                body: options,
            }),
        }),
        downloadSharedPresentationAsset: builder.mutation<Download, DownloadSharedPresentationAssetRequest>({
            query: ({ code, assetId, options }) => ({
                url: `shared/assetPresentations/${code}/assets/${assetId}/download`,
                method: 'POST',
                body: options,
            }),
        }),
    }),
});

export default assetApi;
export const {
    useGetRecentAssetsQuery,
    useGetRecentTrashedAssetsQuery,
    useGetAssetsMutation,
    useGetAllAvailableAssetsQuery,
    useGetAssetsWithCollectionQuery,
    useGetAssetsWithoutCollectionQuery,
    useGetAssetMutation,
    useStoreAssetMutation,
    useStoreAssetIngestMutation,
    useDestroyAssetIngestMutation,
    useAnalyzeAssetIngestMutation,
    useConfigureAssetIngestMutation,
    useUpdateAssetMaterialsMapMutation,
    useStoreAssetMaterialMutation,
    useDestroyAssetMaterialMutation,
    useStoreAssetAttachmentMutation,
    useDestroyAssetAttachmentMutation,
    useProcessAssetSetOriginMutation,
    useProcessAssetUvIslandsMutation,
    useProcessAssetUvUnwrapMutation,
    useFinalizeAssetIngestMutation,
    useUpdateAssetMutation,
    useShareAssetLinkMutation,
    useCopyAssetMutation,
    useCopyAssetsMutation,
    useDownloadAssetMutation,
    useArchiveAssetMutation,
    useArchiveAssetsMutation,
    useRestoreAssetMutation,
    useRestoreAssetsMutation,
    usePurgeAssetMutation,
    useEmptyAssetsTrashMutation,
    useStoreAssetThumbnailMutation,
    useUpdateAssetNodesMutation,
    useUpdateAssetHiddenNodesMutation,
    useUpdateDefaultAssetSceneMutation,
    useDeleteDefaultAssetSceneMutation,
    useGetSharedAssetMutation,
    useGetSharedAssetMembersForMentionsQuery,
    useMoveAssetMutation,
    useMoveAssetsMutation,
    useDownloadSharedAssetMutation,
    useDownloadSharedPresentationAssetMutation,
    useSharedAssetGrantMutation,
} = assetApi;
